import React, { useMemo } from "react";
import { Link, useLocation } from "react-router-dom";

import type { FC } from "react";

import "./link-tab.scss";

type LinkTabProps = {
  title: string;
  path: string;
};

const LinkTab: FC<LinkTabProps> = (props) => {
  const { title, path } = props;

  const location = useLocation();

  const className = useMemo(
    () =>
      location.pathname === path ? "link-tab link-tab--active" : "link-tab",
    [location.pathname, path]
  );

  return (
    <div className={className}>
      <Link to={path}>{title}</Link>
    </div>
  );
};

export { LinkTab };
