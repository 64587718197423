import React from "react";
import { Tabs } from "antd";

import type { TabsProps } from "antd";
import type { FC } from "react";

import "./pill-tabs.scss";

type PillTabsProps = TabsProps;

const PillTabs: FC<PillTabsProps> = (props) => (
  <Tabs
    {...props}
    className={`orm-pill-tabs ${props.className || ""}`.trim()}
  />
);

export { PillTabs };
