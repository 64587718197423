export const colors = [
  {
    background: "#FCF0E0",
    color: "#C47630",
  },
  {
    background: "#FAE9E7",
    color: "#F15F5F",
  },
  {
    background: "#EDDDFF",
    color: "#832CE8",
  },
  {
    background: "#E8F0FE",
    color: "#335CF7",
  },
  {
    background: "#E0FBFF",
    color: "#45C9DB",
  },
];
