import { useQuery } from "@apollo/client";
import { useEffect, useMemo } from "react";
import { useSelector } from "react-redux";

import { filters, serpFilters } from "@/api/filters";
import { useAppDispatch } from "@/store";
import { setFiltersData } from "@/store/filters";
import { FilterIcons } from "@/consts/filter-icons";
// import { getFiltersFromDynamicFilters } from "@/modules/shared/utils";

import type { TRequest, TResponse } from "@/api/filters/types";
import type { TState } from "@/store/types";

const useFilters = (
  request?: TRequest,
  isDispatch?: boolean,
  requestName = "getFilters",
  filtersList?: any[],
  clientId?: number | null
) => {
  const dispatch = useAppDispatch();
  const { date } = useSelector((state: TState) => state.filters);

  const dates = {
    from: date.startDate?.format("YYYY-MM-DD") || "",
    to: date.endDate?.format("YYYY-MM-DD") || "",
  };

  const defaultIds = [
    "country",
    "city",
    "retailer",
    "brand",
    "seller",
    "category",
    "skuId",
    "availability",
  ];

  const defaultSerpIds = [
    "country",
    "city",
    "retailer",
    "brand",
    "rawBrand",
    "serpType",
    "keyword",
    // "rawCategory",
    "skuId",
  ];

  const { getFilters } = filters;
  const { getSerpFilters } = serpFilters;

  const {
    data: response,
    loading,
    error,
  } = useQuery<TResponse>(
    requestName === "getFilters" ? getFilters : getSerpFilters,
    {
      variables: {
        dates,
        ids:
          request?.attributes ||
          (requestName === "getFilters" ? defaultIds : defaultSerpIds),
        filters: filtersList || [],
        clientId,
      },
      fetchPolicy: "no-cache",
    }
  );

  const data = useMemo(
    () =>
      response
        ? {
            filters: response.filters.map((filter) => ({
              ...filter,
              icon: FilterIcons[filter.id],
            })),
          }
        : undefined,
    [response]
  );

  useEffect(() => {
    if (isDispatch) dispatch(setFiltersData(data));
  }, [data]);

  return {
    data,
    loading,
    error,
  };
};

export { useFilters };
