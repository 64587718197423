import { configureStore } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";

import { filtersReducer } from "./filters";
import { sheetsReducer } from "./sheets";
import { productListReducer } from "./product-list";
import { tableFieldsReduser } from "./table-fields";
import { userReducer } from "./user";

const store = configureStore({
  devTools: process.env.NODE_ENV !== "production",
  reducer: {
    filters: filtersReducer,
    sheets: sheetsReducer,
    productList: productListReducer,
    tableFields: tableFieldsReduser,
    user: userReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }),
});

export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = (): AppDispatch => useDispatch<AppDispatch>();

export default store;
