import React from "react";
import { MenuOutlined } from "@ant-design/icons";

import EyeClosed from "@/icons/modules/shared/eye-closed.svg";
import EyeOpened from "@/icons/modules/shared/eye-opened.svg";

const DndListItem = ({ dnd, item }: any) => (
  <li
    ref={dnd.item.ref}
    className={`${dnd.item.classes}${!item.selected ? " not-selected" : ""}`}
    style={{ ...dnd.item.styles, ...dnd.handler.styles }}
    {...dnd.handler.listeners}
  >
    <MenuOutlined />
    <p style={{ color: item.selected ? undefined : "#8D9CA8" }}>{item.label}</p>
    <div
      className="eye"
      onClick={item.disabled ? undefined : item.handleSelectedKey}
      style={{
        opacity: item.disabled ? 0.5 : undefined,
        cursor: item.disabled ? "not-allowed" : undefined,
      }}
    >
      {item.selected ? <EyeOpened /> : <EyeClosed />}
    </div>
  </li>
);

export { DndListItem };
