import { useSelector } from "react-redux";
import { gql, useLazyQuery } from "@apollo/client";

import { getFiltersFromDynamicFilters } from "@/modules/shared/utils";
import { camelToUpperSnake } from "@/utils/string-manipulations";

import type { TState } from "@/store/types";

const useExportProducts = () => {
  const { date, dynamicFilters, searchText } = useSelector(
    (state: TState) => state.filters
  );

  const { showFields } = useSelector((state: TState) => state.tableFields);
  const fields = showFields?.map((field) =>
    camelToUpperSnake(String(field.dataIndex))
  );

  const EXPORT_PRODUCTS = gql`
    query ExportProducts(
      $dates: DateRangeInput!
      $filters: [FilterInput!]
      $searchText: String
      $fields: [ProductExportFieldEnum!]
    ) {
      exportProducts(
        dates: $dates
        exportType: EXPORT_PRODUCT_XLSX
        filters: $filters
        searchText: $searchText
        searchFields: ["title"]
        exportFields: $fields
        sort: { by: DOOS, order: DESC }
      )
    }
  `;

  const dates = {
    from: date.startDate?.format("YYYY-MM-DD") || "",
    to: date.endDate?.format("YYYY-MM-DD") || "",
  };

  const [getProductsExcelLink, { loading, error, data }] = useLazyQuery<{
    exportProducts: string;
  }>(EXPORT_PRODUCTS, {
    variables: {
      dates,
      filters: getFiltersFromDynamicFilters(dynamicFilters),
      searchText,
      fields,
    },
    fetchPolicy: "no-cache",
  });

  return {
    getProductsExcelLink,
    loading,
    data,
    error,
  };
};

export { useExportProducts };
