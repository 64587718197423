import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { useNormalizedMediumGraphData } from "@/modules/shared/hooks/use-normalized-medium-graph-data";
import { useMovableGraph } from "@/modules/shared/hooks/use-movable-graph";

import type { TMediumGraphLegend } from "@/modules/shared/molecules";
import type { TabsProps } from "antd";

type TUseMediumGroupingVerticalGraph = {
  colorsWithLabel: TMediumGraphLegend;
  tabs: string[];
};

const camelToShoutSnakeCase = (str: string) =>
  str.replace(/([A-Z])/g, "_$1").toUpperCase();

const useMediumGroupingVerticalGraph = <T>({
  colorsWithLabel,
  tabs,
  getDataHook,
}: TUseMediumGroupingVerticalGraph & {
  getDataHook: (grouping: string) => {
    data: T | undefined;
    loading: boolean;
  };
}) => {
  const { t, i18n } = useTranslation();
  const [activeTab, setActiveTab] = useState(tabs[0]);

  const productUnavailableDaysTabs = useMemo<
    Exclude<TabsProps["items"], undefined>
  >(
    () =>
      tabs.map((tab) => ({
        key: tab,
        label: t(`shared.${tab}`),
      })),
    [i18n.language]
  );

  const grouping = camelToShoutSnakeCase(activeTab);

  const { data: apiData } = getDataHook(grouping);

  const parseKey =
    apiData && typeof apiData === "object"
      ? Object.keys(apiData)[0]
      : undefined;

  const parsedData = apiData
    ? (apiData as Record<string, any>)[parseKey!]
    : undefined;

  const normalizeFn = useCallback(
    (item: Exclude<typeof parsedData, undefined>[number]) => ({
      ...item,
      ...colorsWithLabel.reduce(
        (acc, { key }) => ({
          ...acc,
          [key]: item.all && ((item[key] / item.all) * 100).toFixed(2),
        }),
        {}
      ),
    }),
    [colorsWithLabel]
  );

  const {
    data: normalizedData,
    isNormalizedMode,
    setIsNormalizedMode,
  } = useNormalizedMediumGraphData(parsedData, normalizeFn);
  const { data, onMoveGraphBy, possibleMoveGraph } = useMovableGraph(
    normalizedData,
    {
      isHorizontal: { height: 241 },
    }
  );

  const colors = colorsWithLabel.map((color) => color.color);
  const keys = colorsWithLabel.map((color) => color.key);

  return {
    activeTab,
    setActiveTab,
    colors,
    keys,
    data,
    onMoveGraphBy,
    possibleMoveGraph,
    isNormalizedMode,
    setIsNormalizedMode,
    productUnavailableDaysTabs,
  };
};

export { useMediumGroupingVerticalGraph };
