import { layouts } from "./layouts/en";
import { pages } from "./pages/en";
import { modules } from "./modules/en";
import shared from "./shared/en.json";

export const en = {
  ...layouts,
  ...pages,
  modules,
  shared,
};
