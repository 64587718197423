import { createSlice } from "@reduxjs/toolkit";

import type { TSheetState } from "./types";

import { reducers } from "./reducers";

const initialState: TSheetState = {
  defaultSheet: {
    id: "default",
    title: "modules.molecules.lists.default-sheet",
    page: "",
    section: "",
    selectedFilters: [],
    selectedColumns: [],
  },
  defaultSheetId: null,
};

export const sheetsSlice = createSlice({
  name: "Sheets",
  initialState,
  reducers: {
    ...reducers,
    reset: () => initialState,
  },
});

const sheetsReducer = sheetsSlice.reducer;

export type { TSheetState };

export { sheetsReducer };

export const {
  reset,
  setDefaultSheetFilter,
  setDefaultSheetFilters,
  deleteDefaultSheetFilter,
  resetDefaultSheetFilters,
  setDefaultSheetId,
} = sheetsSlice.actions;
