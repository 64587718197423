export const downloadFile = (url: string, fileName: string) => {
  // Create a new anchor element
  const anchor = document.createElement("a");

  // Set the href and download attributes of the anchor
  anchor.href = url;
  anchor.download = fileName || "download";

  // Append the anchor to the body
  document.body.appendChild(anchor);

  // Trigger the download by simulating a click on the anchor
  anchor.click();

  // Remove the anchor from the body
  document.body.removeChild(anchor);
};
