import { createSlice } from "@reduxjs/toolkit";

import type { TProductListState } from "@/store/product-list/types";

import { reducers } from "./reducers";

const initialState: TProductListState = {
  useLists: false,
};

export const productListSlice = createSlice({
  name: "ProductList",
  initialState,
  reducers: {
    ...reducers,
    reset: () => initialState,
  },
});

const productListReducer = productListSlice.reducer;

export type { TProductListState };

export { productListReducer };

export const { reset, setUseLists } = productListSlice.actions;
