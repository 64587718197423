import React from "react";
import { useTranslation } from "react-i18next";

import EmptyGraphIcon from "@/icons/shared/empty-graph-icon.svg";

import "./empty-medium-graph.scss";

const EmptyMediumGraph = ({ emptyText }: { emptyText?: string }) => {
  const { t } = useTranslation();

  return (
    <div className="empty-medium-graph">
      <div className="empty-medium-graph__wrapper">
        <EmptyGraphIcon />
        <p>{emptyText || t("shared.graph-unavailable")}</p>
      </div>
    </div>
  );
};

export { EmptyMediumGraph };
