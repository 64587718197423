import { layouts } from "./layouts/ru";
import { pages } from "./pages/ru";
import { modules } from "./modules/ru";
import shared from "./shared/ru.json";

export const ru = {
  ...layouts,
  ...pages,
  modules,
  shared,
};
