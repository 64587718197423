import { useMemo } from "react";
import { matchPath, useLocation } from "react-router-dom";

import type { TRoute } from "@/app/types";

const useCurrentRoute = (routesList: TRoute[]) => {
  const location = useLocation();

  return useMemo(() => {
    for (let i = 0; i < routesList.length; i += 1) {
      if (
        matchPath(location.pathname, {
          exact: true,
          path: routesList[i].path,
        })
      ) {
        if (routesList[i].parentKey) {
          return routesList.find(
            (route) => routesList[i].parentKey === route.key
          );
        }

        return routesList[i];
      }
    }

    return undefined;
  }, [location.pathname]);
};

export { useCurrentRoute };
