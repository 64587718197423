import React from "react";

import CountryIcon from "@/icons/filters/country.svg";
import AvailabilityIcon from "@/icons/filters/availability.svg";
import RetailerIcon from "@/icons/filters/retailer.svg";
import BrandIcon from "@/icons/filters/brand.svg";
import SellerIcon from "@/icons/filters/seller.svg";

export const FilterIcons: Record<string, JSX.Element> = {
  country: <CountryIcon />,
  retailer: <RetailerIcon />,
  availability: <AvailabilityIcon />,
  brand: <BrandIcon />,
  seller: <SellerIcon />,
};
