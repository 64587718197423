import type { TProductsTableColumn } from "@/modules/shared/molecules/products-table/types";
import type { TTableFieldsState } from "@/store/table-fields/types";

export const reducers = {
  setShowFields(
    state: TTableFieldsState,
    action: { payload: TProductsTableColumn<any>[] }
  ) {
    if (action.payload) {
      state.showFields = action.payload;
    }
  },
  setVisibleFields(state: TTableFieldsState, action: { payload: string[] }) {
    if (action.payload) {
      state.visibleFields = action.payload;
    }
  },
};
