import { gql } from "@apollo/client";

export default {
  getSerpFilters: gql`
    query GetSerpFilters($dates: DateRangeInput!, $ids: [String!]!) {
      filters: getSerpFilters(dates: $dates, ids: $ids) {
        id
        type
        title
        values {
          id
          value
          description
        }
      }
    }
  `,
};
