import dayjs, { type Dayjs, type OpUnitType, type QUnitType } from "dayjs";

const getPrecisionDates = (
  item: { date: string },
  dateUnit: QUnitType | OpUnitType,
  formatDate: (date1: Dayjs, date2?: Dayjs) => string
) => {
  const itemDate = dayjs(item.date);
  const startDate = itemDate.startOf(dateUnit as QUnitType | OpUnitType);
  const endDate = itemDate.endOf(dateUnit as QUnitType | OpUnitType);
  const dateDifference = endDate.diff(startDate, "day") + 1;
  const formattedDate = formatDate(startDate, endDate);

  return {
    itemDate,
    startDate,
    endDate,
    dateDifference,
    formattedDate,
  };
};

export { getPrecisionDates };
