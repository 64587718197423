export const getFiltersFromDynamicFilters = (
  dynamicFilters: Record<string, string | string[]>
) => {
  const filters = Object.entries(dynamicFilters).map(([key, value]) => ({
    id: key === "inStock" ? "availability" : key === "soldby" ? "seller" : key,
    values: Array.isArray(value) ? value : value,
  }));

  return filters.length ? filters : undefined;
};
