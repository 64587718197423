import { useSelector } from "react-redux";
import { gql, useLazyQuery } from "@apollo/client";

import type { TState } from "@/store/types";

export type TResponse = {
  getProductAnyValues: {
    products: {
      spid: number;
      skuId: string;
      url: string[];
    }[];
  };
};

const useProductAnyValuesLazy = () => {
  const { date } = useSelector((state: TState) => state.filters);

  const GET_PRODUCTS_ANY_VALUES = gql`
    query GetProductAnyValues(
      $dates: DateRangeInput!
      $filters: [FilterInput!]
      $grouping: [ProductAnyValuesGroupingEnum!]!
    ) {
      getProductAnyValues(
        dates: $dates
        filters: $filters
        grouping: $grouping
      ) {
        products {
          skuId
          spid
          url
          idSoldby
        }
      }
    }
  `;

  const dates = {
    from: date.startDate?.format("YYYY-MM-DD") || "",
    to: date.endDate?.format("YYYY-MM-DD") || "",
  };

  const [getProductAnyValuesLazy, { loading, error, data }] =
    useLazyQuery<TResponse>(GET_PRODUCTS_ANY_VALUES, {
      variables: {
        dates,
      },
      fetchPolicy: "no-cache",
    });

  return { getProductAnyValuesLazy, loading, error, data };
};

export { useProductAnyValuesLazy };
