import React, { useMemo, useRef, useState } from "react";
import dayjs from "dayjs";
import { Button, Select } from "antd";
import { useTranslation } from "react-i18next";

import Calendar from "@/icons/filters/calendar.svg";
import NextMonth from "@/icons/filters/next-month.svg";
import PrevMonth from "@/icons/filters/prev-month.svg";
import Arrow from "@/icons/filters/arrow.svg";

import "./page-filter-calendar.scss";

type TDay = {
  day: number;
  month: number;
  year: number;
  type: "active" | "in-active";
};

const chunkArray = (array: TDay[], chunkSize: number) => {
  const chunkedArray = [];
  for (let i = 0; i < array.length; i += chunkSize) {
    const chunk = array.slice(i, i + chunkSize);
    chunkedArray.push(chunk);
  }

  return chunkedArray;
};

const monthOptions = [
  {
    label: "modules.atoms.filter-calendar.jan",
    value: 0,
  },
  {
    label: "modules.atoms.filter-calendar.feb",
    value: 1,
  },
  {
    label: "modules.atoms.filter-calendar.mar",
    value: 2,
  },
  {
    label: "modules.atoms.filter-calendar.apr",
    value: 3,
  },
  {
    label: "modules.atoms.filter-calendar.may",
    value: 4,
  },
  {
    label: "modules.atoms.filter-calendar.jun",
    value: 5,
  },
  {
    label: "modules.atoms.filter-calendar.jul",
    value: 6,
  },
  {
    label: "modules.atoms.filter-calendar.aug",
    value: 7,
  },
  {
    label: "modules.atoms.filter-calendar.sep",
    value: 8,
  },
  {
    label: "modules.atoms.filter-calendar.okt",
    value: 9,
  },
  {
    label: "modules.atoms.filter-calendar.nov",
    value: 10,
  },
  {
    label: "modules.atoms.filter-calendar.dec",
    value: 11,
  },
];

type PageFilterCalendarProps = {
  initialDate?: {
    startDate: dayjs.Dayjs | null;
    endDate: dayjs.Dayjs | null;
  };
  onSubmit: (date: {
    startDate: dayjs.Dayjs | null;
    endDate: dayjs.Dayjs | null;
  }) => void;
};

const PageFilterCalendar = ({
  initialDate,
  onSubmit,
}: PageFilterCalendarProps) => {
  const { t, i18n } = useTranslation();

  const currentDate = dayjs();
  const [showCalendar, setShowCalendar] = useState(false);
  const [startDate, setStartDate] = useState<dayjs.Dayjs | null>(
    initialDate?.startDate || currentDate
  );
  const [endDate, setEndDate] = useState<dayjs.Dayjs | null>(
    initialDate?.endDate || currentDate
  );
  const [currentMonth, setCurrentMonth] = useState(
    (initialDate?.endDate || currentDate).month()
  );
  const [currentYear, setCurrentYear] = useState(
    (initialDate?.endDate || currentDate).year()
  );

  const [nowSelecting, setNowSelecting] = useState(1);

  const ref = useRef<HTMLDivElement>(null);

  const daysInCurrentMonth = useMemo(() => {
    const days: TDay[] = [];

    const selectedDate = dayjs().year(currentYear).month(currentMonth);
    const startOfMonth = selectedDate.startOf("month");
    const endOfMonth = selectedDate.endOf("month");
    const prevMonthDate = currentMonth
      ? dayjs()
          .year(currentYear)
          .month(currentMonth - 1)
      : dayjs()
          .year(currentYear - 1)
          .month(11);
    const nextMonthDate =
      currentMonth === 11
        ? dayjs()
            .year(currentYear + 1)
            .month(currentMonth)
        : dayjs()
            .year(currentYear)
            .month(currentMonth + 1);

    // fix calendar
    for (let i = 2; i <= startOfMonth.day(); i += 1) {
      days.push({
        day: prevMonthDate.endOf("month").date() - (startOfMonth.day() - i),
        month: prevMonthDate.month(),
        year: prevMonthDate.year(),
        type: "in-active",
      });
    }

    for (let i = startOfMonth.date(); i <= endOfMonth.date(); i += 1) {
      days.push({
        day: i,
        month: currentMonth,
        year: currentYear,
        type: "active",
      });
    }

    for (let i = 1; i < 7 - endOfMonth.day(); i += 1) {
      days.push({
        day: i,
        month: nextMonthDate.month(),
        year: nextMonthDate.year(),
        type: "in-active",
      });
    }

    return days;
  }, [currentMonth, currentYear]);
  const yearOptions = useMemo(() => {
    const result = [];
    for (let i = 1980; i <= currentYear; i += 1) {
      result.push({
        value: i,
        label: i,
      });
    }

    return result;
  }, []);

  const handleShowCalendar = () => setShowCalendar((prevState) => !prevState);

  const getCellClass = (item: TDay) => {
    let current = null;
    const dayItem = dayjs().year(item.year).month(item.month).date(item.day);

    if (dayItem.format("DD-MM-YYYY") === currentDate.format("DD-MM-YYYY")) {
      current = "filter-calendar__dropdown-selected-current";
    }

    if (item.type === "in-active") {
      return ["filter-calendar__dropdown-selected-disabled", current].join(" ");
    }

    if (dayItem.format("DD-MM-YYYY") === startDate?.format("DD-MM-YYYY")) {
      if (startDate?.format("DD-MM-YYYY") === endDate?.format("DD-MM-YYYY")) {
        return ["filter-calendar__dropdown-selected", current].join(" ");
      }

      return ["filter-calendar__dropdown-selected-start", current].join(" ");
    }

    if (dayItem.format("DD-MM-YYYY") === endDate?.format("DD-MM-YYYY")) {
      return ["filter-calendar__dropdown-selected-end", current].join(" ");
    }

    if (dayItem.isAfter(startDate) && dayItem.isBefore(endDate)) {
      return ["filter-calendar__dropdown-selected-between", current].join(" ");
    }

    if (nowSelecting === 2 && dayItem?.isBefore(startDate)) {
      return ["filter-calendar__dropdown-selected-disabled", current].join(" ");
    }

    return ["filter-calendar__dropdown-cell", current].join(" ");
  };

  const handleClick = (item: TDay) => () => {
    if (item.type === "in-active") {
      return undefined;
    }

    const dayItem = dayjs().year(item.year).month(item.month).date(item.day);

    if (nowSelecting === 2 && dayItem?.isBefore(startDate)) {
      return undefined;
    }

    if (nowSelecting === 1) {
      setStartDate(dayItem);
      setNowSelecting(2);
      setEndDate(null);
    }

    if (nowSelecting === 2) {
      setEndDate(dayItem);
      setNowSelecting(1);
    }
  };

  const handleMonthSelect = (item: number) => {
    setCurrentMonth(item);
  };

  const handleYearSelect = (item: number) => {
    setCurrentYear(item);
  };

  const handleNextMonth = () => {
    if (currentMonth === 11) {
      setCurrentYear((prevState) => prevState + 1);
      setCurrentMonth(0);
    } else {
      setCurrentMonth((prevState) => prevState + 1);
    }
  };

  const handlePrevMonth = () => {
    if (currentMonth === 0) {
      setCurrentYear((prevState) => prevState - 1);
      setCurrentMonth(11);
    } else {
      setCurrentMonth((prevState) => prevState - 1);
    }
  };

  const handleSelectToday = () => {
    setStartDate(currentDate);
    setEndDate(currentDate);
    setCurrentYear(currentDate.year());
    setCurrentMonth(currentDate.month());
  };

  const handleSelectThisWeek = () => {
    setStartDate(currentDate.startOf("week"));
    setEndDate(currentDate.endOf("week"));
    setCurrentYear(currentDate.year());
    setCurrentMonth(currentDate.month());
  };

  const handleSelectPrevWeek = () => {
    setStartDate(currentDate.subtract(1, "week").startOf("week"));
    setEndDate(currentDate.subtract(1, "week").endOf("week"));
    setCurrentYear(currentDate.year());
    setCurrentMonth(currentDate.month());
  };

  const handleSelectThisMonth = () => {
    setStartDate(currentDate.startOf("month"));
    setEndDate(currentDate.endOf("month"));
    setCurrentYear(currentDate.year());
    setCurrentMonth(currentDate.month());
  };

  const handleSelectPrevMonth = () => {
    setStartDate(currentDate.subtract(1, "month").startOf("month"));
    setEndDate(currentDate.subtract(1, "month").endOf("month"));
    setCurrentYear(currentDate.year());
    setCurrentMonth(currentDate.month() - 1);
  };

  const handleSelectThisYear = () => {
    setStartDate(currentDate.startOf("year"));
    setEndDate(currentDate.endOf("year"));
    setCurrentYear(currentDate.year());
    setCurrentMonth(currentDate.month());
  };

  const handleSubmit = () => {
    if (!endDate) {
      setEndDate(startDate);
      setNowSelecting(1);
    }

    onSubmit({
      startDate,
      endDate: endDate || startDate,
    });
    setShowCalendar(false);
  };

  const handleReset = () => {
    setStartDate(initialDate?.startDate || currentDate);
    setEndDate(initialDate?.endDate || currentDate);
    setCurrentMonth((initialDate?.endDate || currentDate).month());
    setCurrentYear((initialDate?.endDate || currentDate).year());
    setNowSelecting(1);
    handleSubmit();
  };

  const formattedStartDate = useMemo(
    () => startDate?.locale(i18n.language)?.format("DD MMM") || "",
    [i18n.language, startDate]
  );

  const formattedEndDate = useMemo(
    () => endDate?.locale(i18n.language)?.format("DD MMM") || "",
    [i18n.language, endDate]
  );

  return (
    <div className="filter-calendar">
      <div
        onClick={handleShowCalendar}
        className={`filter-calendar__input${
          showCalendar ? " filter-calendar__input--open" : ""
        }`}
      >
        <span>{`${formattedStartDate} - ${formattedEndDate}`}</span>
        <Calendar />
      </div>
      {showCalendar && (
        <>
          <div
            className="filter-calendar__dropdown-bg"
            onClick={handleShowCalendar}
          />
          <div ref={ref} className="filter-calendar__dropdown">
            <div className="filter-calendar__dropdown-left">
              <button
                className="filter-calendar__dropdown-left__item"
                onClick={handleSelectToday}
                type="button"
              >
                {t("modules.atoms.filter-calendar.today")}
              </button>
              <button
                className="filter-calendar__dropdown-left__item"
                onClick={handleSelectThisWeek}
                type="button"
              >
                {t("modules.atoms.filter-calendar.this-week")}
              </button>
              <button
                className="filter-calendar__dropdown-left__item"
                onClick={handleSelectPrevWeek}
                type="button"
              >
                {t("modules.atoms.filter-calendar.last-week")}
              </button>
              <button
                className="filter-calendar__dropdown-left__item"
                onClick={handleSelectThisMonth}
                type="button"
              >
                {t("modules.atoms.filter-calendar.this-month")}
              </button>
              <button
                className="filter-calendar__dropdown-left__item"
                onClick={handleSelectPrevMonth}
                type="button"
              >
                {t("modules.atoms.filter-calendar.last-month")}
              </button>
              <button
                className="filter-calendar__dropdown-left__item"
                onClick={handleSelectThisYear}
                type="button"
              >
                {t("modules.atoms.filter-calendar.by-year")}
              </button>
            </div>
            <div className="filter-calendar__dropdown-right">
              <div className="filter-calendar__dropdown-right-header">
                <div className="filter-calendar__dropdown-right-header-selectors">
                  <Select
                    onSelect={handleMonthSelect}
                    suffixIcon={<Arrow />}
                    value={currentMonth}
                    options={monthOptions.map(({ label, ...option }) => ({
                      ...option,
                      label: t(label),
                    }))}
                  />
                  <Select
                    onSelect={handleYearSelect}
                    options={yearOptions}
                    suffixIcon={<Arrow />}
                    value={currentYear}
                  />
                </div>
                <div className="filter-calendar__dropdown-right-header-switcher">
                  <button onClick={handlePrevMonth} type="button">
                    <PrevMonth />
                  </button>
                  <button onClick={handleNextMonth} type="button">
                    <NextMonth />
                  </button>
                </div>
              </div>
              <table>
                <thead>
                  <tr>
                    <th>{t("modules.atoms.filter-calendar.mo")}</th>
                    <th>{t("modules.atoms.filter-calendar.tu")}</th>
                    <th>{t("modules.atoms.filter-calendar.we")}</th>
                    <th>{t("modules.atoms.filter-calendar.th")}</th>
                    <th>{t("modules.atoms.filter-calendar.fr")}</th>
                    <th>{t("modules.atoms.filter-calendar.sa")}</th>
                    <th>{t("modules.atoms.filter-calendar.su")}</th>
                  </tr>
                </thead>
                <tbody>
                  {chunkArray(daysInCurrentMonth, 7).map(
                    (array, arrayIndex) => (
                      <tr key={`${arrayIndex}`}>
                        {array.map((item, index) => (
                          <td key={`${arrayIndex}-${index}`}>
                            <button
                              className={getCellClass(item)}
                              onClick={handleClick(item)}
                              type="button"
                            >
                              {item.day}
                            </button>
                          </td>
                        ))}
                      </tr>
                    )
                  )}
                </tbody>
              </table>
              <div className="filter-calendar__dropdown-right-buttons">
                <Button onClick={handleReset}>
                  {t("modules.atoms.filter-calendar.cancel")}
                </Button>
                <Button onClick={handleSubmit} type="primary">
                  {t("modules.atoms.filter-calendar.apply")}
                </Button>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export { PageFilterCalendar };
