import type {
  TUserState,
  TClientData,
  TUserData,
  TFilterConfigItem,
} from "@/store/user";

export const reducers = {
  setClientData(state: TUserState, action: { payload: TClientData | null }) {
    state.clientData = action.payload;
  },
  setUserData(state: TUserState, action: { payload: TUserData | null }) {
    state.userData = action.payload;
  },
  setFilterConfigs(
    state: TUserState,
    action: { payload: TFilterConfigItem[] }
  ) {
    state.filterConfigs = action.payload;
  },
};
