import { createSlice } from "@reduxjs/toolkit";

import { reducers } from "@/store/user/reducers";

import type {
  TUserState,
  TClientData,
  TUserData,
  TFilterConfigItem,
} from "@/store/user/types";

const initialState: TUserState = {
  clientData: null,
  userData: null,
  filterConfigs: [],
};

export const userSlice = createSlice({
  name: "User",
  initialState,
  reducers: {
    ...reducers,
    reset: () => initialState,
  },
});

const userReducer = userSlice.reducer;

export type { TUserState, TClientData, TUserData, TFilterConfigItem };

export { userReducer };

export const { setClientData, setUserData, setFilterConfigs } =
  userSlice.actions;
