import React from "react";
import { ConfigProvider } from "antd";

import type { FC, PropsWithChildren } from "react";

import { CssVariablesProvider } from "./css-variables-provider";

const ThemeProvider: FC<PropsWithChildren> = ({ children }) => (
  <ConfigProvider
    theme={{
      token: {
        colorInfo: "#3B444B",
        colorIcon: "#3B444B",
        colorPrimary: "#4480F3",
        borderRadius: 2,
      },
    }}
  >
    <CssVariablesProvider>{children}</CssVariablesProvider>
  </ConfigProvider>
);

export { ThemeProvider };
