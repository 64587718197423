import type { TSheetState } from "./types";

export const reducers = {
  setDefaultSheetFilter(
    state: TSheetState,
    action: { payload: { key: string; value: string[] } }
  ) {
    if (!action.payload.value.length) {
      state.defaultSheet.selectedFilters =
        state.defaultSheet.selectedFilters.filter(
          (filter) => filter.id !== action.payload.key
        );
    } else if (
      state.defaultSheet.selectedFilters.find(
        (filter) => filter.id === action.payload.key
      )
    ) {
      state.defaultSheet.selectedFilters =
        state.defaultSheet.selectedFilters.map((filter) => {
          if (filter.id === action.payload.key) {
            return {
              ...filter,
              values: action.payload.value.map((val) => ({
                id: val,
                value: val,
                description: val,
              })),
            };
          }

          return filter;
        });
    } else {
      state.defaultSheet.selectedFilters = [
        ...state.defaultSheet.selectedFilters,
        {
          id: action.payload.key,
          values: action.payload.value.map((val) => ({
            id: val,
            value: val,
            description: null,
          })),
        },
      ];
    }
  },
  deleteDefaultSheetFilter(state: TSheetState, action: { payload: string }) {
    state.defaultSheet.selectedFilters =
      state.defaultSheet.selectedFilters.filter(
        (filter) => filter.id !== action.payload
      );
  },
  setDefaultSheetFilters(
    state: TSheetState,
    action: { payload: Record<string, string | string[]> }
  ) {
    state.defaultSheet.selectedFilters = Object.entries(action.payload).map(
      ([key, value]) => ({
        id: key,
        values: Array.isArray(value)
          ? value.map((val) => ({
              id: val,
              value: val,
              description: val,
            }))
          : [
              {
                id: value,
                value,
                description: value,
              },
            ],
      })
    );
  },
  resetDefaultSheetFilters(state: TSheetState) {
    state.defaultSheet.selectedFilters = [];
  },
  setDefaultSheetId(state: TSheetState, action: { payload: string | null }) {
    state.defaultSheetId = action.payload;
  },
};
