import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import Arrow from "@/icons/filters/arrow.svg";

import type { FC } from "react";

import "./to-table-button.scss";

type ToTableButtonProps = {
  to: string;
};

const ToTableButton: FC<ToTableButtonProps> = ({ to }) => {
  const { t } = useTranslation();

  return (
    <div>
      <Link className="to-table-button" to={to}>
        {t("shared.table.show-more")}
        <Arrow />
      </Link>
    </div>
  );
};

export { ToTableButton };
