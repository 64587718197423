import { gql, useLazyQuery } from "@apollo/client";
import { useSelector } from "react-redux";
import { useEffect } from "react";

import type { TState } from "@/store/types";

export type TResponse = {
  getFilter: {
    __typename: string;
    id: string;
    type: string;
    title: string;
    suggest: boolean;
    values: {
      __typename: string;
      id: string;
      value: string;
      description: string | null;
    }[];
  };
};

const useFilter = (id: string) => {
  const { date } = useSelector((state: TState) => state.filters);

  const dates = {
    from: date.startDate?.format("YYYY-MM-DD") || "",
    to: date.endDate?.format("YYYY-MM-DD") || "",
  };
  const GET_FILTER = gql`
    query GetFilter($dates: DateRangeInput!, $id: String!) {
      getFilter(dates: $dates, id: $id) {
        id
        type
        title
        suggest
        values {
          id
          value
          description
        }
      }
    }
  `;

  const [getFilter, { loading, error, data }] = useLazyQuery<TResponse>(
    GET_FILTER,
    {
      variables: {
        dates,
        id: id === "soldby" ? "seller" : id,
      },
    }
  );

  useEffect(() => {
    getFilter();
  }, [id]);

  return {
    loading,
    error,
    data,
  };
};

export { useFilter };
