import React from "react";
import loadable from "@loadable/component";

import { useCurrentRoute } from "@/modules/shared/hooks";
import { routes } from "@/app/consts";
import { usePathnameChange } from "@/hooks/usePathnameChange";

import type { FC, PropsWithChildren } from "react";

const EmptyLayout: FC<PropsWithChildren> = ({ children }) => <>{children}</>;

const AppLayout: FC<PropsWithChildren> = ({ children }) => {
  const currentRoute = useCurrentRoute(routes);

  usePathnameChange();

  const Layout = currentRoute
    ? loadable(
        () => import(/* webpackPrefetch: true */ `../${currentRoute.layout}`)
      )
    : EmptyLayout;

  return <Layout>{children}</Layout>;
};

export { AppLayout };
