import React from "react";
import { useToken } from "antd/es/theme/internal";

import type { CSSProperties, FC, PropsWithChildren } from "react";

const CssVariablesProvider: FC<PropsWithChildren> = ({ children }) => {
  const [, token] = useToken();

  return (
    <div
      style={
        {
          "--ant-color-primary": token.colorPrimary,
          "--ant-color-error": token.colorError,
          "--ant-color-success": token.colorSuccess,
          "--accent": "#4480F3",
          "--primary-10": "#FFFFFF",
          "--primary-30": "#F1F4F8",
          "--primary-35": "#EAF0F6",
          "--primary-50": "#C4D0DA",
          "--primary-70": "#8D9CA8",
          "--primary-80": "#67727A",
          "--primary-90": "#3B444B",
        } as CSSProperties & Record<string, string | number>
      }
    >
      {children}
    </div>
  );
};

export { CssVariablesProvider };
