import { useMemo, useState } from "react";

const useNormalizedMediumGraphData = <T>(
  initialData: T[] | undefined,
  normalizeFn?: (
    item: Exclude<T[], undefined>[number]
  ) => Exclude<T[], undefined>[number]
) => {
  const [isNormalizedMode, setIsNormalizedMode] = useState(false);

  const data = useMemo<T[] | undefined>(() => {
    let dataToReturn = initialData;

    if (isNormalizedMode && normalizeFn) {
      dataToReturn = dataToReturn?.map(normalizeFn);
    }

    return dataToReturn;
  }, [initialData, isNormalizedMode, normalizeFn]);

  return {
    data,
    isNormalizedMode,
    setIsNormalizedMode,
  };
};

export { useNormalizedMediumGraphData };
