import { createSlice } from "@reduxjs/toolkit";

import { reducers } from "@/store/filters/reducers";

import type { TFilterState } from "@/store/filters/types";

const initialState: TFilterState = {
  date: {
    startDate: null,
    endDate: null,
  },
  dynamicFilters: {},
  filtersData: undefined,
  searchText: undefined,
};

export const filtersSlice = createSlice({
  name: "Filters",
  initialState,
  reducers: {
    ...reducers,
    reset: () => initialState,
  },
});

const filtersReducer = filtersSlice.reducer;

export type { TFilterState };

export { filtersReducer };

export const {
  reset,
  setDateFilter,
  setDynamicFilters,
  setDynamicFilter,
  removeDynamicFilter,
  resetDynamic,
  setFiltersData,
  setSearchText,
} = filtersSlice.actions;
