import { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";

import { sendStatistics } from "@/api/send-statistics";

const usePathnameChange = () => {
  const location = useLocation();
  const prevPathnameRef = useRef(location.pathname);

  useEffect(() => {
    if (prevPathnameRef.current !== location.pathname) {
      sendStatistics({
        action: "enter",
        url: location.pathname,
        data: [],
      });
      prevPathnameRef.current = location.pathname;
    }
  }, [location.pathname]);
};

export { usePathnameChange };
