import shared from "./shared/ru.json";
import filterSelect from "./filter-select/ru.json";
import filterCalendar from "./filter-calendar/ru.json";
import imageslistDrawer from "./images-list-drawer/ru.json";

export const atoms = {
  shared,
  "filter-select": filterSelect,
  "filter-calendar": filterCalendar,
  "images-list-drawer": imageslistDrawer,
};
