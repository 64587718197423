import React from "react";

import DecreaseSvg from "@/icons/modules/shared/decrease.svg";
import IncreaseSvg from "@/icons/modules/shared/increase.svg";

import type { FC } from "react";

import "./changed-value-with-arrow.scss";

type ChangedValueWithArrowProps = {
  value: number | string;
  // any number (<0 for red, >0 for green)
  delta: number;
  deltaText?: string | number;
  className?: string;
};

const ChangedValueWithArrow: FC<ChangedValueWithArrowProps> = ({
  value,
  delta,
  deltaText,
  className,
}) => {
  const isPositive = delta > 0;
  const isNoChange = delta === 0;

  return (
    <div className={`changed-value-with-arrow ${className || ""}`.trim()}>
      <span className="changed-value-with-arrow__value">{value}</span>
      {!isNoChange ? (
        <div
          className={`changed-value-with-arrow__delta changed-value-with-arrow__delta--${
            isPositive ? "positive" : "negative"
          }`}
        >
          <div className="changed-value-with-arrow__delta-icon">
            {isPositive ? <IncreaseSvg /> : <DecreaseSvg />}
          </div>
          <span className="changed-value-with-arrow__delta-value">
            {deltaText === undefined ? delta : deltaText}
          </span>
        </div>
      ) : null}
    </div>
  );
};

export { ChangedValueWithArrow };
