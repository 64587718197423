import React from "react";
import { Space } from "antd";

import type { FC, PropsWithChildren, CSSProperties } from "react";

import "./page-section.scss";

type PageSectionProps = PropsWithChildren<{
  className?: string;
  title?: string;
  childrenCount?: number;
  spaceChildren?: boolean;
}>;

const PageSection: FC<PageSectionProps> = ({
  className,
  title,
  children,
  childrenCount,
  spaceChildren = true,
}) => (
  <div className={`orm-page-section ${className || ""}`.trim()}>
    {title && <h2>{title}</h2>}
    {spaceChildren ? (
      <Space
        size={16}
        style={
          {
            "--section-children-count":
              childrenCount || React.Children.count(children) || 1,
          } as CSSProperties & { "--section-children-count": number }
        }
      >
        {children}
      </Space>
    ) : (
      children
    )}
  </div>
);

export { PageSection };
