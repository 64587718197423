import { createSlice } from "@reduxjs/toolkit";

import { reducers } from "@/store/table-fields/reducers";

import type { TTableFieldsState } from "@/store/table-fields/types";

const initialState: TTableFieldsState = {
  showFields: null,
  visibleFields: [],
};

export const tableFieldsSlice = createSlice({
  name: "TableFields",
  initialState,
  reducers: {
    ...reducers,
    reset: () => initialState,
  },
});

const tableFieldsReduser = tableFieldsSlice.reducer;

export type { TTableFieldsState };

export { tableFieldsReduser };

export const { reset, setShowFields, setVisibleFields } =
  tableFieldsSlice.actions;
