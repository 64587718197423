import React from "react";
import { Slider } from "antd";

import "./diapason-picker.scss";

type DiapasonPickerProps = {
  min: number;
  max: number;
  onChangeComplete: (value: number[]) => void;
  onChange: (value: number[]) => void;
  value: [number, number];
};

const DiapasonPicker = (props: DiapasonPickerProps) => {
  const { min, max, onChangeComplete, value, onChange } = props;

  return (
    <div className="diapason-picker">
      <Slider
        range
        max={max}
        min={min}
        onAfterChange={onChangeComplete}
        onChange={onChange}
        value={value}
      />
      <div className="diapason-picker__values">
        <p>{value?.[0] || min}</p>
        <p>{value?.[1] || max}</p>
      </div>
    </div>
  );
};

export { DiapasonPicker };
