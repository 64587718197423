import React, { createElement, useCallback, useMemo } from "react";
import { animated, to } from "@react-spring/web";
import { useTheme } from "@nivo/core";
import { useTooltip } from "@nivo/tooltip";

import type { MouseEvent } from "react";
import type { BarDatum, BarItemProps } from "@nivo/bar/dist/types/types";

const BarItem = <RawDatum extends BarDatum>(props: BarItemProps<RawDatum>) => {
  const {
    bar: { data, ...bar },

    style: {
      borderColor,
      color,
      height,
      labelColor,
      labelOpacity,
      labelX,
      labelY,
      transform,
      width,
    },

    borderRadius,
    borderWidth,

    label,
    shouldRenderLabel,

    isInteractive,
    onClick,
    onMouseEnter,
    onMouseLeave,

    tooltip,

    isFocusable,
    ariaLabel,
    ariaLabelledBy,
    ariaDescribedBy,
  } = props;

  const theme = useTheme();
  const { showTooltipFromEvent, showTooltipAt, hideTooltip } = useTooltip();

  const renderTooltip = useMemo(
    // eslint-disable-next-line react/display-name
    () => () => createElement(tooltip, { ...bar, ...data }),
    [tooltip, bar, data]
  );

  const handleClick = useCallback(
    (event: MouseEvent<SVGRectElement>) => {
      onClick?.({ color: bar.color, ...data }, event);
    },
    [bar, data, onClick]
  );
  const handleTooltip = useCallback(
    (event: MouseEvent<SVGRectElement>) =>
      showTooltipFromEvent(renderTooltip(), event),
    [showTooltipFromEvent, renderTooltip]
  );
  const handleMouseEnter = useCallback(
    (event: MouseEvent<SVGRectElement>) => {
      onMouseEnter?.(data, event);
      showTooltipFromEvent(renderTooltip(), event);
    },
    [data, onMouseEnter, showTooltipFromEvent, renderTooltip]
  );
  const handleMouseLeave = useCallback(
    (event: MouseEvent<SVGRectElement>) => {
      onMouseLeave?.(data, event);
      hideTooltip();
    },
    [data, hideTooltip, onMouseLeave]
  );

  // extra handlers to allow keyboard navigation
  const handleFocus = useCallback(() => {
    showTooltipAt(renderTooltip(), [bar.absX + bar.width / 2, bar.absY]);
  }, [showTooltipAt, renderTooltip, bar]);
  const handleBlur = useCallback(() => {
    hideTooltip();
  }, [hideTooltip]);

  return (
    <animated.g
      transform={to(transform, (t) => {
        const x = /translate\(\s*([^\s,)]+)/.exec(t);
        const y = /translate\(\s*[^,]+,\s*([^)]+)\)/.exec(t);

        const firstKey = Object.keys(data.data)[0];

        if (y && x && data.id !== firstKey && firstKey === "inStock") {
          return `translate(${x[1]}, ${Number(y[1]) + 8})`;
        }

        if (y && x && data.id !== firstKey && firstKey === "lt3") {
          return `translate(${Number(x[1]) + 8}, ${y[1]})`;
        }

        return t;
      })}
    >
      <defs>
        <clipPath id={`round-corner-${label}`}>
          <animated.rect
            rx={borderRadius}
            ry={borderRadius}
            x="0"
            y="0"
            height={to(height, (value) =>
              Math.max(
                Object.keys(data.data)[0] === "inStock"
                  ? value + borderRadius
                  : value,
                0
              )
            )}
            width={to(width, (value) =>
              Math.max(
                Object.keys(data.data)[0] === "inStock"
                  ? value
                  : value + borderRadius,
                0
              )
            )}
          />
        </clipPath>
      </defs>

      <animated.rect
        aria-describedby={ariaDescribedBy ? ariaDescribedBy(data) : undefined}
        aria-label={ariaLabel ? ariaLabel(data) : undefined}
        aria-labelledby={ariaLabelledBy ? ariaLabelledBy(data) : undefined}
        clipPath={`url(#round-corner-${label})`}
        fill={data.fill ?? color}
        focusable={isFocusable}
        height={to(height, (value) => Math.max(value, 0))}
        onBlur={isInteractive && isFocusable ? handleBlur : undefined}
        onClick={isInteractive ? handleClick : undefined}
        onFocus={isInteractive && isFocusable ? handleFocus : undefined}
        onMouseEnter={isInteractive ? handleMouseEnter : undefined}
        onMouseLeave={isInteractive ? handleMouseLeave : undefined}
        onMouseMove={isInteractive ? handleTooltip : undefined}
        stroke={borderColor}
        strokeWidth={borderWidth}
        tabIndex={isFocusable ? 0 : undefined}
        width={to(width, (value) => Math.max(value, 0))}
      />

      {shouldRenderLabel && (
        <animated.text
          dominantBaseline="central"
          fillOpacity={labelOpacity}
          textAnchor="middle"
          x={labelX}
          y={labelY}
          style={{
            ...theme.labels.text,
            pointerEvents: "none",
            fill: labelColor,
          }}
        >
          {label}
        </animated.text>
      )}
    </animated.g>
  );
};

export { BarItem };
