import React, { useRef } from "react";
import { SnackbarProvider } from "notistack";
import { BrowserRouter } from "react-router-dom";
import { ErrorBoundary } from "react-error-boundary";
import { QueryClient, QueryClientProvider } from "react-query";
import { Provider } from "react-redux";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { jwtDecode } from "jwt-decode";
import { YMInitializer } from "react-yandex-metrika";
import "antd/dist/reset.css";

import store from "@/store";
import AppAppoloClient from "@/app/app-appolo-client";
import { sendStatistics } from "@/api/send-statistics";

import type { FC } from "react";
import type { TToken } from "./types";

import { ThemeProvider } from "./theme-provider";
import ErrorFallback from "./errorFallback";
import GlobalFunctionality from "./globalFunctionality";
import Routes from "./routes";
import { AppWrapper } from "./app-wrapper";
import { AppLayout } from "./app-layout";
import "./styles/popover.scss";
import "./styles/notifications.scss";
import "./app.scss";

dayjs.extend(customParseFormat);

const queryClient = new QueryClient();

const token = localStorage.getItem("token");

let userId: null | number = null;

if (token) {
  const decodedToken: TToken = jwtDecode(token);
  userId = decodedToken?.userId;
}

const App: FC = () => {
  const notistackRef = useRef<any>(null);

  const onClickSnackbar = () => notistackRef.current?.closeSnackbar();

  sendStatistics({
    action: "enter",
    url: window.location.pathname,
    data: [],
  });

  return (
    <Provider store={store}>
      <ThemeProvider>
        <QueryClientProvider client={queryClient}>
          <AppAppoloClient>
            <AppWrapper>
              <YMInitializer
                accounts={[97737188]}
                options={{
                  clickmap: true,
                  trackLinks: true,
                  accurateTrackBounce: true,
                  webvisor: true,
                  userParams: {
                    UserID: userId,
                  },
                }}
              />
              <ErrorBoundary
                FallbackComponent={ErrorFallback}
                onReset={() => {
                  window.location.reload();
                }}
              >
                <SnackbarProvider
                  ref={notistackRef}
                  preventDuplicate
                  maxSnack={3}
                  onClick={onClickSnackbar}
                >
                  <BrowserRouter>
                    <GlobalFunctionality />
                    <AppLayout>
                      <Routes />
                    </AppLayout>
                  </BrowserRouter>
                </SnackbarProvider>
              </ErrorBoundary>
            </AppWrapper>
          </AppAppoloClient>
        </QueryClientProvider>
      </ThemeProvider>
    </Provider>
  );
};

export default App;
