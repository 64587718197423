import React, { createElement, useCallback, useMemo } from "react";
import { animated, to } from "@react-spring/web";
import { useTheme } from "@nivo/core";
import { useTooltip } from "@nivo/tooltip";

import type { MouseEvent } from "react";
import type { BarDatum, BarItemProps } from "@nivo/bar/dist/types/types";

const BarItemVertical = <RawDatum extends BarDatum>(
  props: BarItemProps<RawDatum>
) => {
  const {
    bar,
    style: { color, height, width, transform },

    borderRadius,
    borderWidth,

    label,
    shouldRenderLabel,

    isInteractive,
    onClick,
    onMouseEnter,
    onMouseLeave,

    tooltip,

    isFocusable,
    ariaLabel,
    ariaLabelledBy,
    ariaDescribedBy,
  } = props;

  const theme = useTheme();
  const { showTooltipFromEvent, showTooltipAt, hideTooltip } = useTooltip();

  const renderTooltip = useMemo(
    // eslint-disable-next-line react/display-name
    () => () => createElement(tooltip, { ...bar, ...bar.data }),
    [tooltip, bar, bar.data]
  );

  const notRoundIdsList = ["inStock"];

  const getBarHeight = (value: number): number => {
    const result =
      notRoundIdsList.indexOf(String(props.bar.data.id)) === -1
        ? value + borderRadius
        : value;

    return result;
  };

  const getBarClipHeight = (value: number): number => {
    const result =
      props.bar.data.id !== "inStock" && value > 0
        ? props.bar.y < 205
          ? value + borderRadius * 1.5
          : 220 - props.bar.y
        : value;

    return result;
  };

  const handleClick = useCallback(
    (event: MouseEvent<SVGRectElement>) => {
      onClick?.({ color: bar.color, ...bar.data }, event);
    },
    [bar, bar.data, onClick]
  );
  const handleTooltip = useCallback(
    (event: MouseEvent<SVGRectElement>) =>
      showTooltipFromEvent(renderTooltip(), event),
    [showTooltipFromEvent, renderTooltip]
  );
  const handleMouseEnter = useCallback(
    (event: MouseEvent<SVGRectElement>) => {
      onMouseEnter?.(bar.data, event);
      showTooltipFromEvent(renderTooltip(), event);
    },
    [bar.data, onMouseEnter, showTooltipFromEvent, renderTooltip]
  );
  const handleMouseLeave = useCallback(
    (event: MouseEvent<SVGRectElement>) => {
      onMouseLeave?.(bar.data, event);
      hideTooltip();
    },
    [bar.data, hideTooltip, onMouseLeave]
  );

  // extra handlers to allow keyboard navigation
  const handleFocus = useCallback(() => {
    showTooltipAt(renderTooltip(), [bar.absX + bar.width / 2, bar.absY]);
  }, [showTooltipAt, renderTooltip, bar]);
  const handleBlur = useCallback(() => {
    hideTooltip();
  }, [hideTooltip]);

  return (
    <animated.g
      transform={to(transform, (t) => {
        const x = /translate\(\s*([^\s,)]+)/.exec(t);
        const y = /translate\(\s*[^,]+,\s*([^)]+)\)/.exec(t);

        if (y && x) {
          return `translate(${x[1]}, ${Number(y[1])})`;
        }

        return t;
      })}
    >
      <defs>
        <clipPath id={`round-corner-${label}`}>
          <animated.rect
            height={to(height, (value) => Math.max(getBarClipHeight(value), 0))}
            rx={borderRadius}
            ry={borderRadius}
            width={to(width, (value) => Math.max(value, 0))}
            x="0"
            y="0"
          />
        </clipPath>
      </defs>

      <animated.rect
        aria-label={ariaLabel ? ariaLabel(bar.data) : undefined}
        aria-labelledby={ariaLabelledBy ? ariaLabelledBy(bar.data) : undefined}
        clipPath={`url(#round-corner-${label})`}
        fill={bar.data.fill ?? color}
        focusable={isFocusable}
        height={to(height, (value) => Math.max(getBarHeight(value), 0))}
        onBlur={isInteractive && isFocusable ? handleBlur : undefined}
        onClick={isInteractive ? handleClick : undefined}
        onFocus={isInteractive && isFocusable ? handleFocus : undefined}
        onMouseEnter={isInteractive ? handleMouseEnter : undefined}
        onMouseLeave={isInteractive ? handleMouseLeave : undefined}
        onMouseMove={isInteractive ? handleTooltip : undefined}
        strokeWidth={borderWidth}
        tabIndex={isFocusable ? 0 : undefined}
        width={to(width, (value) => Math.max(value, 0))}
        aria-describedby={
          ariaDescribedBy ? ariaDescribedBy(bar.data) : undefined
        }
      />

      {shouldRenderLabel && (
        <animated.text
          dominantBaseline="central"
          // fillOpacity={labelOpacity}
          textAnchor="middle"
          // x={labelX}
          // y={labelY}
          style={{
            ...theme.labels.text,
            pointerEvents: "none",
            // fill: labelColor,
          }}
        >
          {label}
        </animated.text>
      )}
    </animated.g>
  );
};

export { BarItemVertical };
