import { useSelector } from "react-redux";
import { gql, useLazyQuery } from "@apollo/client";

import type { TState } from "@/store/types";

export type TResponse = {
  getProductUniqValues: {
    products: {
      skuId: string;
    }[];
    pagination: {
      limit: number;
      page: number;
      total: number;
      pages: number;
    };
  };
};

const useProductUniqValuesLazy = () => {
  const { date } = useSelector((state: TState) => state.filters);

  const GET_PRODUCTS_UNIQ_VALUES = gql`
    query GetProductUniqValues(
      $dates: DateRangeInput!
      $filters: [FilterInput!]
      $pagination: PaginationInput!
    ) {
      getProductUniqValues(
        dates: $dates
        filters: $filters
        pagination: $pagination
      ) {
        products {
          skuId
        }
        pagination {
          limit
          page
          total
          pages
        }
      }
    }
  `;

  const dates = {
    from: date.startDate?.format("YYYY-MM-DD") || "",
    to: date.endDate?.format("YYYY-MM-DD") || "",
  };

  const [getProductUniqValuesLazy, { loading, error, data }] =
    useLazyQuery<TResponse>(GET_PRODUCTS_UNIQ_VALUES, {
      variables: {
        dates,
      },
      fetchPolicy: "no-cache",
    });

  return { getProductUniqValuesLazy, loading, error, data };
};

export { useProductUniqValuesLazy };
