import axios from "axios";

type TRequest = {
  action: "enter" | "login";
  url: string | null;
  data: any;
};

const sendStatistics = (data: TRequest) => {
  const token = localStorage.getItem("token");
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };

  if (token) {
    axios.post(
      `${process.env.REACT_APP_API_BASE_URL}/user-stats`,
      data,
      config
    );
  }
};

export { sendStatistics };
