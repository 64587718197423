import React from "react";
import ReactLoading from "react-loading";
import { useToken } from "antd/es/theme/internal";

import type { FC } from "react";

import "./loading.scss";

type LoadingProps = {
  block?: boolean;
  size?: number;
  text?: string;
  zIndex?: number;
  className?: string;
};

const Loading: FC<LoadingProps> = ({
  text,
  zIndex,
  block = false,
  size = 100,
  className,
}) => {
  const [, token] = useToken();

  return (
    <div
      className={`shared-loading ${block ? "shared-loading--block" : ""} ${
        text ? "shared-loading--with-text" : ""
      } ${className || ""}`.trim()}
      style={
        {
          "--z-index": zIndex ?? 10,
        } as any
      }
    >
      <ReactLoading
        color={token.colorPrimary}
        height={size}
        type="bars"
        width={size}
      />
      {text ? <p className="shared-loading__text">{text}</p> : null}
    </div>
  );
};

export { Loading };
