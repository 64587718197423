import React from "react";
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  ApolloLink,
  fromPromise,
} from "@apollo/client";
import createUploadLink from "apollo-upload-client/createUploadLink.mjs";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";

import { auth } from "@/api";

import type { FC, PropsWithChildren } from "react";

const AppAppoloClient: FC<PropsWithChildren> = ({ children }) => {
  const httpLink = createUploadLink({
    uri: process.env.REACT_APP_BACK_BASE_GRAPHQL_URL || "",
  });

  const authLink = setContext((_, { headers }) => {
    // Get the authentication token from wherever it's stored (e.g., localStorage)
    const token = localStorage.getItem("token");

    // Return the headers to the context so HTTP link can read them
    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : undefined, // Include the Bearer token in the header
      },
    };
  });

  const errorLink = onError(({ networkError, operation, forward }) => {
    if (networkError && (networkError as any).statusCode === 401) {
      const refreshToken = localStorage.getItem("refreshToken");

      if (!refreshToken) {
        localStorage.removeItem("token");
        localStorage.setItem("prevURL", window.location.pathname);
        window.location.href = "/auth/login";
      } else {
        return fromPromise(
          auth.refreshToken(refreshToken).then((newToken) => {
            localStorage.setItem("token", newToken.data.token);
            localStorage.setItem("refreshToken", newToken.data.refresh_token);
            const oldHeaders = operation.getContext().headers;
            operation.setContext({
              headers: {
                ...oldHeaders,
                authorization: `Bearer ${newToken}`,
              },
            });
            forward(operation);
          })
        ).flatMap(() => forward(operation));
      }
    }
  });

  // const errorLink = onError(({ networkError }) => {
  //   if (networkError && (networkError as ServerParseError).statusCode === 401) {
  //     localStorage.removeItem("token");

  //     const refreshToken = localStorage.getItem("refreshToken");

  //     if (refreshToken !== null) {
  //       auth
  //         .refreshToken({ refresh_token: refreshToken })
  //         .then((data) => {
  //           // eslint-disable-next-line @typescript-eslint/naming-convention
  //           const { token, refresh_token } = data.data;
  //           localStorage.setItem("token", token);
  //           localStorage.setItem("refreshToken", refresh_token);

  //           // const oldHeaders = operation.getContext().headers;
  //           // operation.setContext({
  //           //   headers: {
  //           //     ...oldHeaders,
  //           //     authorization: `Bearer ${token}`,
  //           //   },
  //           // });

  //           // return forward(operation);
  //         })
  //         .catch(() => {
  //           window.location.href = "/auth/login";
  //         });
  //     }
  //     // window.location.href = "/auth/login";
  //   }
  // });

  // Initialize Apollo Client
  const client = new ApolloClient({
    link: ApolloLink.from([errorLink, authLink, httpLink]),
    cache: new InMemoryCache(),
  });

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};

export default AppAppoloClient;
